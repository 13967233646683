<template>
  <div class="app-container">
    <div class="table-container">
      <form @submit.prevent="onSearch" class="filter-list">
        <div class="filter-item filter-item-mag39">
          <span class="label">商品名称:</span>
          <div class="content">
            <el-input clearable size="small" placeholder="请输入商品名称" v-model.trim="filter.name"></el-input>
          </div>
        </div>
        <div class="filter-item filter-item-mag39">
          <span class="label">商品规格标识:</span>
          <div class="content">
            <el-input clearable size="small" placeholder="请输入商品规格标识" v-model.trim="filter.skuId"></el-input>
          </div>
        </div>
        <div class="filter-item filter-item-row checkbox-div">
          <span class="label">采货类型:</span>
          <div class="content">
            <el-radio-group
              :style="{
                height: showAll ? purchaseTypeRowheight + 'px' : '22px',
              }"
              v-model="filter.purchaseType"
              :max="1"
              id="purchaseTypeGroupBox"
            >
              <el-radio-button
                :key="item.id"
                @click.native.prevent="clickRadioitem(item.id)"
                :label="item.id"
                v-for="item of purchaseTypeList"
                >{{ item.name }}</el-radio-button
              >
            </el-radio-group>
            <el-button
              :icon="showAll ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
              @click="onCategoryBox"
              class="arrow-button"
              size="small"
              type="text"
              v-if="purchaseTypeBtn"
              >{{ showAll ? '收起' : '展开' }}</el-button
            >
          </div>
        </div>
        <div class="filter-item filter-item-row checkbox-div">
          <span class="label">商品品牌:</span>
          <div class="content" ref="brandListBox">
            <el-checkbox-group
              :style="{
                height: brandShowAll ? 'auto' : '32px',
              }"
              v-model="filter.brandIds"
              id="brandListGroupBox"
            >
              <el-checkbox
                :key="item.id"
                :label="item.id"
                :title="item.name"
                v-for="item of brandList"
                >{{ item.name }}</el-checkbox
              >
            </el-checkbox-group>
            <el-button
              :icon="brandShowAll ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
              @click="onBrandListBox"
              class="arrow-button"
              size="small"
              type="text"
              v-if="brandListBtn"
              >{{ brandShowAll ? '收起' : '展开' }}</el-button
            >
          </div>
        </div>
        <div class="filter-item filter-item-row checkbox-div">
          <span class="label">商品分类:</span>
          <div class="content">
            <el-checkbox-group
              :style="{
                height: showAll ? 'auto' : '32px',
              }"
              v-model="filter.categoryIds"
              id="goodsTypeListGroupBox"
            >
              <el-checkbox :key="item.id" :label="item.id" v-for="item of goodsTypeList">{{
                item.name
              }}</el-checkbox>
            </el-checkbox-group>
            <el-button
              :icon="showAll ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
              @click="onCategoryBox"
              class="arrow-button"
              size="small"
              type="text"
              v-if="goodsTypeListBtn"
              >{{ showAll ? '收起' : '展开' }}</el-button
            >
          </div>
        </div>
        <div class="filter-item">
          <el-button native-type="submit" size="small" type="primary">查询</el-button>
          <el-button @click="onResetClick" size="small" class="red">重置</el-button>
        </div>
      </form>
      <div v-show="filterFlag" class="filterFlag">以下数据为当前搜索结果数据</div>
      <div class="refund-list" style="width: 100%" v-loading="listLoading">
        <div v-show="isSiteClassDiv" class="is-site-class-div"></div>
        <div class="table-head table-line" ref="tableHead" :class="isSiteClass">
          <ul class="line">
            <li class="goods-info">
              <div class="cell">商品信息</div>
            </li>
            <li>
              <div class="cell">
                销量
                <ArrowsSort @cortClick="salesVolumeCortClick" ref="salesVolumeCort" />
              </div>
            </li>
            <li class="goods-specification">
              <div class="cell">规格</div>
            </li>
            <li>
              <div class="cell">库存</div>
            </li>
            <li>
              <div class="cell">
                批发价
                <ArrowsSort @cortClick="priceCortClick" ref="priceCort" />
              </div>
            </li>
            <li class="quantityClass">
              <div class="cell">采购量</div>
            </li>
            <li>
              <div class="cell">小计</div>
            </li>
            <li class="operation-class">
              <div class="cell">操作</div>
            </li>
          </ul>
        </div>
        <div :key="index" class="table-body table-line" v-for="(item, index) in list">
          <ul
            :style="{ height: item.unfold ? 85 * item.skuBriefs.length + 24 + 'px' : '186px' }"
            class="line item-list"
          >
            <div @click="unfold(item, index)" class="down" v-if="item.skuBriefs.length > 2">
              <div class="down-cont">
                <span>{{ item.unfold ? '收起' : '展开' }}</span>
                <i :class="item.unfold ? 'el-icon-caret-top' : 'el-icon-caret-bottom'"></i>
              </div>
            </div>
            <!-- 商品信息 -->
            <li class="goods-info">
              <div class="cell cell-flex">
                <router-link :to="'/syoung/commodity/goods-detail/' + item.id">
                  <div class="img-goods-box">
                    <oss-image :src="item.thumbnailUrl" class="table-img" />
                    <span v-if="item.type === 'GLOBAL'" class="img-goods-tag">海淘</span>
                    <span
                      class="img-goods-purchase"
                      v-if="item.singleRestrictionNum > 0 || item.restrictionNum > 0"
                      >限购：{{
                        item.singleRestrictionNum ? item.singleRestrictionNum : item.restrictionNum
                      }}件</span
                    >
                  </div>
                </router-link>
                <div class="cell-flex-column">
                  <div>
                    <div class="name">
                      <router-link
                        :to="'/syoung/commodity/goods-detail/' + item.id"
                        >{{ item.name }}</router-link
                      >
                    </div>
                    <div class="el-tag-box">
                      <el-tag
                        v-if="item.creditBack === '1'"
                        @click="creditDetail(item.brandId)"
                        class="el-tag-box--backVirtualCredit"
                        size="mini"
                        color="#FFE8E8"
                        >返利
                      </el-tag>
                    </div>
                  </div>
                  <div class="typeName">
                    <div>
                      建议零售价: <span>{{ skuBriefsSection(item.skuBriefs, 'retailPrice') }}</span>
                    </div>
                    <div>
                      活动大促价:
                      <span>{{ skuBriefsSection(item.skuBriefs, 'minControlPrice') }}</span>
                    </div>
                    <div>
                      可赚：<span class="red-text">¥{{ item.discountAmount || 0 }}</span>
                    </div>
                    <div>
                      毛利率：<span class="red-text">{{
                        item.grossProfitRate | grossTransform
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <!-- 销量 -->
            <li>
              <div class="cell">
                <div>{{ item.salesVolume || 0 }}</div>
              </div>
            </li>
            <!-- 规格/规格编码 -->
            <li class="goods-specification">
              <div :key="idx" v-for="(datas, idx) in item.skuBriefs" style="height: 80px">
                <div class="cell-heightauto" :class="{ 'gray-text': datas.stock === 0 }">
                  <div>{{ datas | skufiltration() }}</div>
                  <div class="cell-row-auto" style="margin-top: 10px">
                    <!-- <el-tooltip effect="light" :content="datas.id" placement="bottom-start"
                      > -->
                    <span>
                      规格标识:<br />
                      {{ datas.id }}</span
                    >
                    <!-- </el-tooltip> -->
                  </div>
                </div>
              </div>
            </li>
            <!-- 库存 -->
            <li>
              <div :key="idx" class="cell" v-for="(datas, idx) in item.skuBriefs">
                <div :class="{ 'gray-text': datas.stock === 0 }">{{ datas.stock || 0 }}</div>
              </div>
            </li>
            <!-- 批发价 -->
            <li>
              <div :key="idx" class="cell" v-for="(datas, idx) in item.skuBriefs">
                <div :class="{ 'gray-text': datas.stock === 0 }" class="price">
                  ¥{{ datas.price || 0 }}
                  <el-tooltip effect="dark" placement="top-start">
                    <div slot="content">
                      <p>活动大促价：￥{{ datas.minControlPrice || 0 }}</p>
                      <p>建议零售价：￥{{ datas.retailPrice }}</p>
                    </div>
                    <i class="iconfont icon-zhankai"></i>
                  </el-tooltip>
                </div>
              </div>
            </li>
            <!-- 采购量/起订量 -->
            <li class="quantityClass">
              <div :key="idx" class="cell" v-for="(datas, idx) in item.skuBriefs">
                <div :class="{ 'gray-text': datas.stock === 0 }">
                  <el-input-number
                    :min="1"
                    label="描述文字"
                    v-model="datas.quantity"
                  ></el-input-number>
                  <div>起订量： {{ datas.miniOrderQuantity || 0 }}件</div>
                </div>
              </div>
            </li>
            <!-- 小计 -->
            <li>
              <div :key="idx" class="cell" v-for="(datas, idx) in item.skuBriefs">
                <div :class="{ 'gray-text': datas.stock === 0 }" class="price pricexj">
                  ¥{{ (datas.price * datas.quantity).toFixed(2) }}
                </div>
              </div>
            </li>
            <!-- 操作 -->
            <li class="operation-class">
              <template v-if="isstock(item.skuBriefs)">
                <div :key="idx" class="cell cell-acl" v-for="(datas, idx) in item.skuBriefs">
                  <div class="edit">
                    <el-button
                      v-if="datas.quantity > datas.stock"
                      size="small"
                      type="primary"
                      class="btn--disabled"
                      >可购数不足</el-button
                    >
                    <el-button
                      v-else
                      size="small"
                      type="primary"
                      @click="addToShopCart($event, datas)"
                      >加入采购车</el-button
                    >
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="cell cell-acl">
                  <el-button
                    @click="openInform(item)"
                    key="secondOK"
                    class="red"
                    size="small"
                    style="width: 92px"
                    v-if="item.second === '0'"
                    >到货通知</el-button
                  >
                  <el-button class="not" key="secondNot" plain v-else disabled>到货通知</el-button>
                </div>
              </template>
              <div class="collect-box">
                <div
                  @click="toCollect(item)"
                  class="collect"
                  :class="item.isCollect ? 'collect-i-on' : 'collect-i-off'"
                >
                  <i title="收藏" class="iconfont icon-shoucang"></i>
                  <div class="collect-text">收藏</div>
                </div>
                <div
                  @click="onExport(item)"
                  class="download-zip collect"
                  :class="isZIP(item) ? '' : 'no-download-zip'"
                >
                  <i title="下载素材" class="iconfont icon-xaizai"></i>
                  <div class="collect-text">素材</div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="empty" v-if="list.length === 0">暂无数据</div>
      <div class="pagination">
        <el-pagination
          :current-page="pageNo"
          :disabled="listLoading"
          :page-size="pageSize"
          :page-sizes="[7, 20, 30, 40]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          background
          layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  fetchListOnSaleAndNotHidden,
  goodsType,
  addToCart,
  subscribe,
  getlistIsSubscribe,
  getBrandId,
} from '@/api/commodity';
import eventReporter from '@/utils/event-reporter';
import { listAll as brandListAll } from '@/api/setting/commodity/brand';
import pickBy from 'lodash/pickBy';
import pick from 'lodash/pick';
import ArrowsSort from '@/components/ArrowsSort';
import { mapMutations, mapGetters, mapActions } from 'vuex';
import { typeCheck } from '@/utils/typeCheck';
import { remove } from '@/utils/removeLocalStorage';
import { commodityKind } from '@/api/website/home';
import {
  getListFavoriteCommodityByIds,
  cancelFavorites,
  createFavorites,
} from '@/api/commodity/goods-collect';
export default {
  components: {
    ArrowsSort,
  },
  computed: {
    // 过滤
    data() {
      const { ...rest } = this.filter;
      return {
        ...pickBy(rest, val => !!val),
      };
    },
    ...mapGetters(['shop', 'userInfo']),
  },
  name: 'commodity-list',
  data() {
    const initFilter = {
      name: '',
      skuId: '',
      purchaseType: '', // 采货类型
      brandIds: [],
      categoryIds: [],
    };
    return {
      commodityKindId: '', // 二级分组id
      initFilter: initFilter,
      filter: { ...initFilter },
      list: [],
      pageNo: 1,
      pageSize: 7,
      listLoading: false,
      total: 0,
      sortArray: ['ascending', 'descending'],
      orderBy: 'sort desc, item_no desc',
      downTimeVal: '',
      upTimeVal: '',
      exportLoading: false,
      brandList: [], // 品牌列表
      goodsTypeList: [], // 品牌分类
      // 采货分类
      purchaseTypeList: [
        { id: 'PURCHASE', name: '采销' },
        { id: 'DROP_SHIPPING', name: '一件代发' },
      ],
      showAll: false,
      brandShowAll: false,
      collectLoading: false,
      collectListId: [], // 已收藏的id
      isSiteClass: '', // 表头滚动悬浮样式
      tableHeadTop: '', // 表头原始高度
      purchaseTypeBtn: false, //采货类型展开按钮
      brandListBtn: false, //商品品牌展开按钮
      goodsTypeListBtn: false, // 商品分类展开按钮
      purchaseTypeRowheight: 32, //商品品牌展开高度
      brandListRowheight: 32, //商品品牌展开高度
      goodsTypeListRowheight: 32, // 商品分类展开高度
      filterFlag: false,
      isSiteClassDiv: false,
    };
  },
  async created() {
    this.onBusSearchGoods();
    this.getEncode(); // 通过query里面的参数进行数据初始化
    const commodityListData = JSON.parse(localStorage.getItem('commodity/list/data'));
    if (commodityListData) {
      this.filter = { ...this.filter, ...commodityListData };
      this.brandShowAll = JSON.parse(localStorage.getItem('commodity/list/brandShowAll'));
      this.showAll = JSON.parse(localStorage.getItem('commodity/list/showAll'));
    }
    // 如果选中了二级分类跳转过来，需要默认选中二级分类下面全部的商品分类
    if (this.commodityKindId) {
      const rs = await commodityKind(this.commodityKindId);
      this.commodityKindId = '';
      if (rs.code === '0' && rs.data) {
        if (rs.data.izExistsCategory === '0') {
          this.$message({ message: '当前分类下没有可用商品，已为您查询全部数据', type: 'warning' });
        } else {
          const categoryRelationVOs = rs.data.categoryRelationVOs || [];
          const categoryIds = categoryRelationVOs
            .filter(val => val.isHidden !== '1')
            .map(rs => rs.categoryId);
          this.filter = { ...this.filter, categoryIds };
        }
      }
    }
    this.fetchData();
    this.fetchBrandListAll();
    this.fetchGoodsType();
  },
  mounted() {
    window.addEventListener('scroll', this.tableHeadScroll, true);
    this.tableHeadTop = this.$refs.tableHead.getBoundingClientRect().top + 40;

    const [bool, rowheight] = this.isWidthBeyond('purchaseTypeGroupBox');
    this.purchaseTypeBtn = bool;
    this.purchaseTypeRowheight = rowheight;
  },
  destroyed() {
    window.removeEventListener('scroll', this.tableHeadScroll, true);
  },
  beforeDestroy() {
    //解除中央Bus事件绑定
    this.$root.Bus.$off('onBusSearchGoods');
  },
  watch: {
    // 监听属性，数据变化时使用，如果监听属性还会改变就不合适这样使用
    brandList() {
      this.$nextTick(() => {
        const [bool, rowheight] = this.isWidthBeyond('brandListGroupBox');
        this.brandListBtn = bool;
        this.brandListRowheight = rowheight;
      });
    },
    goodsTypeList() {
      this.$nextTick(() => {
        const [bool, rowheight] = this.isWidthBeyond('goodsTypeListGroupBox');
        this.goodsTypeListBtn = bool;
        this.goodsTypeListRowheight = rowheight;
      });
    },
    // 顶部通用导航栏如果重新选择了二级分类，需要进行重新处理
    '$route.query.commodityKindId'() {
      this.$router.go(0);
    },
  },
  filters: {
    grossTransform(grossProfitRate) {
      return (grossProfitRate * 100).toFixed() + '%';
    },
    skufiltration(val) {
      const { firstLevel, secondLevel, threeLevel } = val;
      let txt = '';
      if (firstLevel) {
        txt = firstLevel;
      }
      if (secondLevel) {
        txt += ` / ${secondLevel}`;
      }
      if (threeLevel) {
        txt += ` / ${threeLevel}`;
      }
      return txt;
    },
  },
  methods: {
    ...mapMutations(['UPDATE_SET_CARNUMBER']),
    clickRadioitem(id) {
      this.filter.purchaseType = id === this.filter.purchaseType ? '' : id;
    },
    isWidthBeyond(domid) {
      // 获取宽度
      const domOffsetWidth = document.getElementById(domid).offsetWidth;
      const arr = document.querySelectorAll(`#${domid}>.el-checkbox`);
      let domWidth = 0;
      arr.forEach(item => {
        domWidth = domWidth + item.offsetWidth + 10;
      });

      const bool = domWidth > domOffsetWidth;
      let rowheight = 32;
      if (bool) {
        // 计算出子元素实际高度
        rowheight = Math.ceil(domWidth / domOffsetWidth) * 32;
      }
      return [bool, rowheight];
    },
    onBrandListBox() {
      this.brandShowAll = !this.brandShowAll;
      localStorage.setItem('commodity/list/brandShowAll', JSON.stringify(this.brandShowAll));
    },
    onCategoryBox() {
      this.showAll = !this.showAll;
      localStorage.setItem('commodity/list/showAll', JSON.stringify(this.showAll));
    },
    tableHeadScroll() {
      if (this.getScrollTop() - this.tableHeadTop >= 0) {
        this.isSiteClass = 'table-head-top-site';
        this.isSiteClassDiv = true;
      } else {
        this.isSiteClass = '';
        this.isSiteClassDiv = false;
      }
    },
    getScrollTop() {
      // 取窗口滚动条高度
      let scrollTop = 0;
      if (document.documentElement && document.documentElement.scrollTop) {
        scrollTop = document.documentElement.scrollTop;
      } else if (document.body) {
        scrollTop = document.body.scrollTop;
      }
      return scrollTop;
    },
    onBusSearchGoods() {
      this.$root.$on('onBusSearchGoods', name => {
        if (name && name !== '') {
          this.filter.name = name;
          this.onSearch();
        }
      });
    },
    getEncode() {
      // 获取链接里带的name
      const { query } = this.$route;
      if (query.name && query.name !== '') {
        this.filter.name = query.name;
        localStorage.removeItem('commodity/list/data');
      }
      if (query.brandId && query.brandId !== '') {
        this.filter.brandIds = [query.brandId];
        localStorage.removeItem('commodity/list/data');
      }
      if (query.commodityKindId && query.commodityKindId !== '') {
        this.commodityKindId = query.commodityKindId;
        localStorage.removeItem('commodity/list/data');
      }
    },
    async addToShopCart(event, datas) {
      if (datas.stock === 0) {
        this.$message({
          message: '库存不足无法加入购物车',
          type: 'warning',
        });
        return;
      }
      let quantity = datas.quantity;
      if (datas.quantity > datas.stock) {
        await this.$confirm(
          `采购数量${datas.quantity}超过最大库存${datas.stock}，购物车数量不能超过${datas.stock}是否继续加购?`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        );
        quantity = datas.stock;
      }
      // 通知加购图标组件
      // this.$root.Bus.$emit('addToCartDrop', event.target, item.thumbnailUrl);
      addToCart({
        commodityId: datas.commodityId,
        skuId: datas.id,
        quantity: quantity,
      }).then(res => {
        // 加入购物车埋点
        eventReporter.trackClick(this.$route, {
          name: 'zg_add_goods_click_btn',
          aliasName: '加入购物车',
          query: datas.commodityId,
          event_source: 'goods_list',
        });
        this.UPDATE_SET_CARNUMBER(quantity);
        this.$message({
          message: '成功加入采购车',
          type: 'success',
        });
      });
    },
    fetchGoodsType() {
      goodsType().then(res => {
        this.goodsTypeList = res.data || [];
      });
    },
    fetchBrandListAll() {
      brandListAll().then(res => {
        this.brandList = res.data || [];
      });
    },

    isZIP(item) {
      const { commodityMultimediaVO = [] } = item;
      const zip = commodityMultimediaVO.find(({ type }) => type === 'MATERIAL_ZIP');
      return zip;
    },
    isstock(arr) {
      for (const item of arr) {
        if (item.stock > 0) {
          return true;
        }
      }
      return false;
    },
    // 导出商品
    onExport(item) {
      const { commodityMultimediaVO = [] } = item;
      const zip = commodityMultimediaVO.find(({ type }) => type === 'MATERIAL_ZIP');
      if (!zip) {
        this.$message.info('暂无素材');
        return;
      }
      const jsonValue = JSON.parse(zip.jsonValue);
      window.location.href = jsonValue.url;
    },
    fetchData() {
      this.listLoading = true;
      const listQuery = {
        ...pick(this, ['pageNo', 'pageSize', 'data']),
        ...(this.orderBy && { orderBy: this.orderBy }),
      };
      this.filterFlag = false;
      for (const item in this.data) {
        if (typeCheck(this.data[item]) === 'Array' && this.data[item].length > 0) {
          this.filterFlag = true;
          break;
        }
        if (this.data[item] && typeCheck(this.data[item]) === 'String') {
          this.filterFlag = true;
          break;
        }
      }
      fetchListOnSaleAndNotHidden(listQuery)
        .then(response => {
          const keyword = listQuery.data?.name;
          if (!response.data.list || response.data.list.length === 0) {
            this.list = [];
            this.total = 0;
            const param = {
              keyword,
              total: this.total,
            };
            this.$scReporter.track('ZG_SEARCH_GOODS', param);
            return Promise.rejact();
          }
          this.commodityIds = response.data.list.map(({ id }) => id);
          this.list =
            response.data.list.map(item => ({
              ...item,
              skuBriefs: item.skuBriefs.map(key => ({
                ...key,
                quantity: key.miniOrderQuantity || 1, // 本地新增采购量字段
              })),
            })) || [];
          this.total = response.data?.total;
          this.keyword = this.data.name;
           const param = {
           keyword: this.keyword,
           total: this.total,
            };
            if (param.keyword) {
              this.$scReporter.track('ZG_SEARCH_GOODS', param);
              //console.log('ZG_GOODS_SEARCH', JSON.stringify(param));
            }

          // let brandIds = [];
          // this.list.forEach(item => {
          //   if (item.creditBack === '1') {
          //     brandIds.push(item.brandId);
          //   }
          // });
          // brandIds = Array.from(new Set(brandIds));
          // if (brandIds) {
          //   listByBrandId(brandIds).then(res => {
          //     if (res.data.length > 0) {
          //       res.data.forEach(item => {
          //         this.list.forEach((listItem, index) => {
          //           if (item.brandId === listItem.brandId && listItem.creditBack === '1') {
          //             this.list[index].creditBack = '1';
          //           } else {
          //             this.list[index].creditBack = '0';
          //           }
          //         });
          //       });
          //     } else {
          //       this.list.forEach(listItem => {
          //         listItem.creditBack = '0';
          //       });
          //     }
          //   });
          // }
          return getListFavoriteCommodityByIds({
            commodityIds: this.commodityIds,
            memberId: this.userInfo.memberId,
          });
        })
        .then(res => {
          const { data = [] } = res;
          if (data.length !== 0) {
            this.collectListId = data;
            this.editCollect();
          }

          return getlistIsSubscribe(this.commodityIds);
        })
        .then(res => {
          const { data = [] } = res;
          const list = [...this.list];
          const combined = list.reduce((acc, cur) => {
            const target = acc.find(e => e.id === cur.first);
            if (target) {
              Object.assign(target, cur);
            } else {
              acc.push(cur);
            }
            return acc;
          }, data);
          this.list = combined;
        })
        .catch(orrer => {
          // console.orrer(orrer);
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    skuBriefsSection(arr, key) {
      const keyArr = arr.map(item => item[key] ?? 0);
      if (keyArr.find(num => num > 0)) {
        keyArr.sort((a, b) => {
          return a - b;
        });
        const le = new Set(keyArr); // 去重
        const c = [...le]; //转换为数组
        if (c.length === 1) {
          return `¥${c[0]}`;
        }
        return `¥${c[0]} ~ ¥${c[c.length - 1]}`;
      } else {
        return `¥0`;
      }
    },
    // 收藏样式切换
    editCollect() {
      const list = [...this.list];
      const set = new Set(this.collectListId);
      for (const item of list) {
        item.isCollect = set.has(item.id);
      }
      this.list = [...list];
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.fetchData();
    },
    onSearch() {
      this.pageNo = 1;
      localStorage.setItem('commodity/list/data', JSON.stringify(this.data));
      this.fetchData();
    },
    onResetClick() {
      this.filter = { ...this.initFilter };
      this.pageNo = 1;
      this.pageSize = 7;
      this.$refs.salesVolumeCort.onReset();
      this.$refs.priceCort.onReset();
      remove();
      this.fetchData();
    },
    // 规格大于2可以展开
    unfold(v, inx) {
      const list = [...this.list];
      list[inx].unfold = !list[inx].unfold;
      this.list = [...list];
    },
    salesVolumeCortClick(val) {
      /* 销量排序 销量升序：sales_volume asc  销量降序：sales_volume desc */
      const orderBy = { max: 'sales_volume asc', min: 'sales_volume desc' }[val] || '';
      if (this.orderBy === orderBy) return;
      this.orderBy = orderBy;
      this.$refs.priceCort.onReset();
      this.fetchData();
    },
    priceCortClick(val) {
      // 批发价排序  价格升序：price asc 价格降序：price desc
      const orderBy = { max: 'price asc', min: 'price desc' }[val] || '';
      if (this.orderBy === orderBy) return;
      this.orderBy = orderBy;
      this.$refs.salesVolumeCort.onReset();
      this.fetchData();
    },
    openInform(item) {
      this.$prompt('输入手机号，该商品到货将第一时间短信提醒您', '提示', {
        closeOnClickModal: false,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^1[34578]\d{9}$/,
        inputErrorMessage: '手机号格式不正确',
        inputValue: this.userInfo.mobile,
      })
        .then(({ value }) => {
          return subscribe({
            commodityId: item.id,
            mobile: value,
          });
        })
        .then(res => {
          this.$message({
            type: 'success',
            message: '设置成功',
          });
          this.fetchData();
        })
        .catch(() => {});
    },
    // 商品收藏
    ...mapActions(['getCountStatisticsHot']),
    toCollect(item) {
      if (this.collectLoading) return;
      this.collectLoading = true;
      let req = createFavorites;
      if (item.isCollect) {
        req = cancelFavorites;
        const collectListId = this.collectListId.filter(key => key !== item.id);
        this.collectListId = collectListId;
      } else {
        this.collectListId.push(item.id);
      }
      req(item.id)
        .then(res => {
          this.$message.success(item.isCollect ? '成功取消收藏' : '收藏成功');
          this.getCountStatisticsHot();
          this.editCollect();
        })
        .finally(() => {
          this.collectLoading = false;
        });
    },
    // 返利弹窗
    creditDetail(id) {
      getBrandId(id).then(res => {
        this.$alert(res.data.description, '', {
          confirmButtonText: '确定',
          center: true,
        });
      });
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import './styles';

.creditBack {
  color: #fff;
  padding: 2px 4px;
  background: red;
}

.red {
  color: #ab0033;
  border-color: #ab0033;
}

.red-text {
  color: #ab0033;
}

.filterFlag {
  margin-bottom: 10px;
}

.is-site-class-div {
  height: 40px;
  width: 100%;
}
</style>

