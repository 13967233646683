import { withExtTenantIdRequest } from '@/utils/request';

// 获取商品类型
export function fetchListOnSaleAndNotHidden (data) {
  return withExtTenantIdRequest({
    url: '/commodity/api/commodityEs/listOnSaleAndNotHidden',
    method: 'post',
    data
  });
}

// 获取商品类型
export function fetchCommodityType () {
  return withExtTenantIdRequest({
    url: '/common/api/dict/listByType?type=commodity_type',
    method: 'get'
  });
}
export function add (data) {
  return withExtTenantIdRequest({
    url: '/commodity/api/commodity/create',
    method: 'post',
    data
  });
}

export function getById (id) {
  return withExtTenantIdRequest({
    url: '/commodity/api/commodity/get',
    method: 'get',
    params: {
      id
    }
  });
}

export function cancelHiddenCommodity (id) {
  return withExtTenantIdRequest({
    url: '/commodity-service/api/commodity/cancelHiddenCommodity',
    method: 'get',
    params: {
      id
    }
  });
}

export function update (data) {
  return withExtTenantIdRequest({
    url: '/commodity/api/commodity/update',
    method: 'post',
    data
  });
}

export function listCommodityParams () {
  return withExtTenantIdRequest({
    url: '/commodity/api/commodityParam/listAll',
    method: 'post',
    data: {}
  });
}
// 根据优惠券id获取优惠券信息
export function getByCouponId (id) {
  return withExtTenantIdRequest({
    url: `/marketing/api/coupon/get?id=${id}`,
    method: 'get'
  });
}
// 自动上架
export function autoOnShelve (data) {
  return withExtTenantIdRequest({
    url: '/commodity-service/api/commodity/autoOnShelve',
    method: 'post',
    data
  });
}

// 自动下架
export function autoOffShelve (data) {
  return withExtTenantIdRequest({
    url: '/commodity-service/api/commodity/autoOffShelve',
    method: 'post',
    data
  });
}
// 取消商品自动下架
export function cancelAutoOffShelve (id) {
  return withExtTenantIdRequest({
    url: '/commodity-service/api/commodity/cancelAutoOffShelve',
    method: 'get',
    params: {
      id
    }
  });
}

// 取消商品自动上架
export function cancelAutoOnShelve (id) {
  return withExtTenantIdRequest({
    url: '/commodity-service/api/commodity/cancelAutoOnShelve',
    method: 'get',
    params: {
      id
    }
  });
}

// 判断商品是否参与营销活动
export function validationByCommodityId (id) {
  return withExtTenantIdRequest({
    url: '/marketing/api/commodityActivityVal/validationByCommodityId',
    params: { commodityId: id }
  });
}

// 获取所有商品品类列表
export function goodsType () {
  return withExtTenantIdRequest({
    url: '/commodity/api/category/listVisibleCategory',
    method: 'get'
  });
}

// 添加商品到购物车
export function addToCart (data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/merchantShoppingCart/addToCart',
    method: 'post',
    data
  });
}

// 订阅商品到货通知
export function subscribe (data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/arrivalNoticeSubscribe/subscribe',
    method: 'post',
    data
  });
}

// 商品是否可订阅到货通知
export function setIsSubscribe (commodityId) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/arrivalNoticeSubscribe/isSubscribe?commodityId=${commodityId}`,
    method: 'post'
  });
}

// 商品是否可订阅到货信息 数组
export function getlistIsSubscribe (data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/arrivalNoticeSubscribe/listIsSubscribe',
    method: 'post',
    data
  });
}

// 返点详情
export function getBrandId (id) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/virtualCreditActivity/getByBrandId?brandId=${id}`,
    method: 'get'
  });
}

// 返点详情批量
export function listByBrandId (data) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/virtualCreditActivity/listByBrandId`,
    method: 'post',
    data
  });
}
