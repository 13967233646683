<template>
  <div @click="cortClick" class="sort-box">
    <i
      :class="action==='max'?'action':''"
      @click.stop="_cortClick('max')"
      class="sort-caret ascending"
    ></i>
    <i
      :class="action==='min'?'action':''"
      @click.stop="_cortClick('min')"
      class="sort-caret descending"
    ></i>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  data() {
    return {
      action: '',
      frequency: 0
    };
  },
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    onReset() {
      // 存在
      this.action = '';
      this.frequency = 0;
    },
    cortClick() {
      let v = '';
      this.frequency++;
      if (this.frequency === 1) {
        v = 'max';
      } else if (this.frequency === 2) {
        v = 'min';
      } else if (this.frequency >= 3) {
        this.action = '';
        this.frequency = 0;
        v = '';
      }
      this.action = v;
      this.$emit('cortClick', v);
    },
    _cortClick(val) {
      this.action = val;
      this.$emit('cortClick', val);
    }
  }
};
</script>

<style lang='scss'  scoped>
.sort-box {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  height: 34px;
  width: 24px;
  vertical-align: middle;
  cursor: pointer;
  overflow: initial;
  position: relative;
  .sort-caret {
    width: 0;
    height: 0;
    border: 5px solid transparent;
    position: absolute;
    left: 7px;
    &.ascending {
      border-bottom-color: #c0c4cc;
      top: 5px;
      &.action {
        border-bottom-color: #409eff;
      }
    }
    &.descending {
      border-top-color: #c0c4cc;
      bottom: 7px;
      &.action {
        border-top-color: #409eff;
      }
    }
  }
}
</style>