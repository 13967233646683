export function typeCheck(data) {
  if (Object.prototype.toString.call(data) === '[object Array]') {
    return 'Array';
  }
  if (Object.prototype.toString.call(data) === '[object Number]') {
    return 'Number';
  }
  if (Object.prototype.toString.call(data) === '[object Object]') {
    return 'Object';
  }
  if (Object.prototype.toString.call(data) === '[object String]') {
    return 'String';
  }
}
